import axios from 'axios'
import { wholeUrl } from "@/api/phpUrl.js";
// import { javawholeUrl } from "@/api/javaUrl.js";
import { getToken } from '@/utils/auth'
import { Loading } from 'element-ui'
import { Message } from "element-ui";


const mimeMap = {
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  zip: 'application/zip',
  pdf: 'application/pdf',
}
//'str'为下载路径
//'stly'为下载类型(默认xlsx)
//'name'下载文件名称
//'data'为入参
//'type'判断是否小程序接口(默认为php接口)
//'method'为请求方式(默认get请求)
var javawholeUrl = window.localStorage.getItem('javaBaseUrl')
export function downLoadZip(str, stly, name, params, type, method) {
  const imgdownUrl1 = type == 'app' ? javawholeUrl : wholeUrl//判断是否为小程序端接口;
  var loading = Loading.service({
    lock: true,
    text: "下载中",
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.7)",
  })

  var url = imgdownUrl1 + str



  axios({
    method: method || 'get',
    url: url,
    responseType: 'blob',
    headers: { 'TOKEN': getToken() },
    [method == "post" ? 'data' : 'params']: params
  }).then(response => {
    const res = response.data;
    if (res.type === "text/html") {
      var enc = new TextDecoder("utf-8");
      res.arrayBuffer().then(buffer => {
        let data = JSON.parse(enc.decode(new Uint8Array(buffer))) || {};
        loading.close();
        return Message({
          message: data.message || "服务器繁忙，请刷新重试",
          type: "error",
          duration: 5 * 1000
        });
      });
    } else {
      var state = response.headers['content-type'];
      if (stly == "zip") {
        state = mimeMap.zip
      } else if (stly == "pdf") {
        state = mimeMap.pdf
      } else {
        state = mimeMap.xlsx
      }
      resolveBlob(response, state, name, loading)
    }
  })

}
/**
 * 解析blob响应内容并下载
 * @param {*} res blob响应内容
 * @param {String} mimeType MIME类型
 */
export function resolveBlob(res, mimeType, name, loading) {
  const aLink = document.createElement('a')
  var blob = new Blob([res.data], { type: mimeType })
  // //从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
  // var patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
  // var contentDisposition = decodeURI(res.headers['content-disposition'])
  // var result = patt.exec(contentDisposition)
  // var fileName = result[1]
  // fileName = fileName.replace(/\"/g, '')
  aLink.href = URL.createObjectURL(blob)
  aLink.setAttribute('download', name) // 设置下载文件名称
  document.body.appendChild(aLink)
  aLink.click()
  document.body.appendChild(aLink)
  loading.close();
}